<template>
  <div>
    <div class="row lists-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.LISTS')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("LISTS.NEW_LIST") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="listTable.selected.length == 0"
            >
              {{ $t("LISTS.DEL_LIST") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="listTable.selected"
              :headers="headers"
              :items="listTable.lists"
              :single-select="listTable.singleSelect"
              :search="search"
              show-select
              item-key="id"
              class="elevation-1"
              ref="listTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.listtype="{ item }">
                <span>{{ listType[item.listtype * 1 - 1] }}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="showItemInfor(item)"
                  v-if="item.listtype == 1 && canAdd"
                  v-b-tooltip.hover
                  :title="$t('COMMON.EDIT')"
                >
                  edit
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add list modal------------- -->
            <b-modal
              id="addList"
              ref="addList"
              :title="$t('LISTS.ADD_LIST')"
              size="lg"
            >
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="4">
                    <label
                      >{{ $t("LISTS.LIST_NAME") }} @
                      {{ $t("COMMON.DOMAIN") }}</label
                    >
                  </b-col>
                  <b-col sm="8">
                    <b-row>
                      <b-col sm="4" class="groupFormUser">
                        <b-form-input
                          id="listName"
                          v-model="$v.addForm.listName.$model"
                          :state="validateState('listName')"
                          :placeholder="$t('LISTS.LIST_NAME')"
                          aria-describedby="input-listName-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-listName-feedback">
                          {{ $t("VALIDATION.REQUIRED_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                      <b-col sm="8" class="groupFormDomain">
                        <b-input-group prepend="@">
                          <b-form-select
                            v-model="addForm.domain.selected"
                            value-field="domain"
                            text-field="domain"
                            :options="addForm.domain.data"
                          ></b-form-select>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("LISTS.LIST_TYPE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.listType.selected"
                      value-field="value"
                      text-field="name"
                      :options="listTypeData"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="createItem()">
                  {{ $t("LISTS.ADD_LIST") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------edit list modal start------------- -->
            <b-modal
              hide-footer
              id="editList"
              ref="editList"
              size="lg"
              :title="updateForm.editTitle"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="5">
                    <b-form-select
                      v-model="updateForm.memberMode.selected"
                      value-field="value"
                      text-field="text"
                      :options="memberModeData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5" v-if="updateForm.memberMode.selected">
                    <b-form-input
                      v-model="$v.addForm.memberName.$model"
                      :state="validateState('memberName')"
                      :placeholder="$t('LISTS.EMAIL_ADDRESS')"
                      aria-describedby="input-memberName-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-memberName-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col sm="5" v-else>
                    <b-form-select
                      v-model="updateForm.memberEmail.selected"
                      value-field="value"
                      text-field="text"
                      :options="updateForm.memberEmail.data"
                    ></b-form-select>
                  </b-col>
                  <b-col sm="4">
                    <b-button variant="primary" @click="addMember()">
                      {{ $t("LISTS.ADD_MEMBER") }}
                    </b-button>
                  </b-col>
                  <b-col sm="3">
                    <b-button
                      variant="danger"
                      v-b-modal.delMembersConfirm-modal
                      :disabled="memberTable.selected.length == 0"
                    >
                      {{ $t("COMMON.DELETE") }}
                    </b-button>
                  </b-col>
                </b-row>
                <v-data-table
                  v-model="memberTable.selected"
                  :headers="memberTableHeaders"
                  :items="memberTable.members"
                  :single-select="memberTable.singleSelect"
                  show-select
                  item-key="id"
                  class="elevation-1"
                  ref="memberTable"
                  :footer-props="{
                    showFirstLastPage: true,
                  }"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      medium
                      color="dark"
                      @click="saveDelOneMember(item)"
                      v-b-modal.delMemberOneConfirm-modal
                      v-b-tooltip.hover
                      :title="$t('COMMON.DELETE')"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </b-container>
            </b-modal>
            <!-- ----------edit list modal end------------- -->

            <!-- ----------delete confirm modal------------- -->
            <!-- ----------- delete one list confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.lname }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete lists confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete one member confirm modal -->
            <b-modal
              id="delMemberOneConfirm-modal"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delMemberConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delMemberConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneMember">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete members confirm modal -->
            <b-modal
              id="delMembersConfirm-modal"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delMemberConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delMemberConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteMembers">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "lists",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    listTable: {
      singleSelect: false,
      lists: [],
      selected: [],
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    addForm: {
      domain: {
        selected: "",
        data: [],
      },
      listType: {
        selected: 1,
      },
      listName: "",
      memberName: "",
    },
    delItem: "",
    updateForm: {
      id: "",
      lname: "",
      domain: "",
      editTitle: "",
      delMember: "",
      memberMode: {
        selected: 0,
      },
      memberEmail: {
        selected: "",
        data: [],
      },
    },
    memberTable: {
      singleSelect: false,
      members: [],
      selected: [],
    },
  }),
  computed: {
    headers: function () {
      return [
        { text: this.$t("LISTS.LIST_NAME"), value: "lname" },
        { text: this.$t("LISTS.LIST_TYPE"), value: "listtype" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    listType: function () {
      return [
        this.$t("LISTS.DISTRIBUTION_LIST"),
        // this.$t("LISTS.SPAM_RETRAINER"),
        // this.$t("LISTS.NOT_SPAM_RETRAINER"),
        // this.$t("LISTS.EXCHNAGE_ACCOUNT")
      ];
    },
    listTypeData: function () {
      return [
        { value: 1, name: this.$t("LISTS.DISTRIBUTION_LIST") },
        // { value: 2, name: this.$t("LISTS.SPAM_RETRAINER") },
        // { value: 3, name: this.$t("LISTS.NOT_SPAM_RETRAINER") },
        // { value: 4, name: this.$t("LISTS.EXCHNAGE_ACCOUNT") }
      ];
    },
    memberModeData: function () {
      return [
        { value: 0, text: this.$t("LISTS.INTERNAL_MEMBERS") },
        { value: 1, text: this.$t("LISTS.EXTERNAL_MEMBERS") },
      ];
    },
    memberTableHeaders: function () {
      return [
        { text: this.$t("LISTS.MEMBER"), value: "member" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    canAdd: function () {
      return this.hasPermission("lists", 5);
    },
    canRemove: function () {
      return this.hasPermission("lists", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.addForm.domain.data = res.returndata;
        this.addForm.domain.selected = res.returndata[0].domain;
        this.getListsReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      listName: {
        required,
      },
      memberName: {
        required,
        email,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.LISTS") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs["addList"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getListsReqest(domain) {
      this.listTable.lists = [];
      this.listTable.selected = [];
      postRequest({
        action: "getAllListsDomain",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.listTable.lists = res.returndata;
        } else {
          this.listTable.lists = [];
        }
      });
    },
    getListsForSelectedDomain(selectedDomain) {
      this.getListsReqest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      this.addForm.domain.selected = this.selectDomain.selectedDomain.domain;
      this.$refs["addList"].show();
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.listName.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "addList",
        token: localStorage.id_token,
        newlisttype: this.addForm.listType.selected,
        newlname: this.addForm.listName,
        domain: selectedDomainForAdd,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getListsReqest(selectedDomainForAdd);
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addForm.listName = "";
        this.addForm.domain.selected = this.addForm.domain.data[0].domain;
        this.addForm.listType.selected = this.listTypeData[0].value;
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(lid) {
      postRequest({
        action: "removeList",
        token: localStorage.id_token,
        lid: lid,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
          for (let i = 0; i < this.listTable.lists.length; i++) {
            if (this.listTable.lists[i].id == lid) {
              this.listTable.lists.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.id);
    },
    deleteItems() {
      if (this.listTable.selected.length > 0) {
        this.listTable.selected.forEach((el) => {
          this.deleteRequest(el.id);
        });
      }
    },

    /* ------edit item----- */
    showItemInfor(item) {
      this.memberTable.members = [];
      this.memberTable.selected = [];
      this.updateForm.id = item.id;
      this.updateForm.memberEmail.data = [];
      postRequest({
        action: "getListMembers",
        token: localStorage.id_token,
        lid: item.id,
      }).then((res) => {
        if (res.returncode) {
          this.memberTable.members = res.returndata;
          this.updateForm.editTitle =
            this.$t("COMMON.EDIT") + res.lname + "@" + res.domain;
          this.updateForm.lname = res.lname;
          this.updateForm.domain = res.domain;
          postRequest({
            action: "getAllUsersDomain",
            token: localStorage.id_token,
            domain: this.selectDomain.selectedDomain.domain,
            offset: 0,
            limit: 0,
            sorting: 0,
            slicing: 0,
            getOTPAndToken: 0,
            showQuota: 0,
          }).then((res) => {
            if (res.returncode) {
              this.updateForm.memberEmail.selected = res.returndata[0].email;
              res.returndata.forEach((el) => {
                this.updateForm.memberEmail.data.push({
                  value: el.email,
                  text: el.email,
                });
              });
            } else {
              this.updateForm.memberMode.selected = 1;
            }
            this.$refs["editList"].show();
          });
        } else {
          this.memberTable.members = [];
        }
      });
    },
    addMember() {
      let memberName = this.addForm.memberName;
      if (this.updateForm.memberMode.selected) {
        this.$v.addForm.$touch();
        if (this.$v.addForm.memberName.$anyError) {
          return;
        }
      } else {
        memberName = this.updateForm.memberEmail.selected;
      }

      const lid = this.updateForm.id;
      postRequest({
        action: "addListMember",
        token: localStorage.id_token,
        editdomain: this.updateForm.domain,
        editlname: this.updateForm.lname,
        editlid: lid,
        newmember: memberName,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          postRequest({
            action: "getListMembers",
            token: localStorage.id_token,
            lid: lid,
          }).then((res) => {
            if (res.returncode) {
              this.memberTable.members = res.returndata;
              this.addForm.memberName = "";
              this.$v.$reset();
            } else {
              this.memberTable.members = [];
            }
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    saveDelOneMember(item) {
      this.updateForm.delMember = item.id;
    },
    deleteMemberRequest(id) {
      postRequest({
        action: "removeListMember",
        token: localStorage.id_token,
        mid: id,
      }).then((res) => {
        this.confirmModalClose("delMemberConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          for (let i = 0; i < this.memberTable.members.length; i++) {
            if (this.memberTable.members[i].id == id) {
              this.memberTable.members.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneMember() {
      const id = this.updateForm.delMember;
      this.deleteMemberRequest(id);
    },
    deleteMembers() {
      if (this.memberTable.selected.length > 0) {
        this.memberTable.selected.forEach((el) => {
          this.deleteMemberRequest(el.id);
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getListsForSelectedDomain(newValue.domain);
    },
  },
};
</script>
